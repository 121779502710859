
import { Options, Vue } from "vue-class-component";
import { Action } from "vuex-class";
export default class WebViewDemo extends Vue {
  private windowTmp: any;
  private form: any = {
    name: "",
    idCardImages: [],
    barcode: "",
  };

  private afterRead(file: any) {
    console.log(file);
    console.log(file.path);
    this.form.idCardImages = [file];
  }

  public created() {
    (window as any).flutter_inappwebview.callHandler(
      "test",
      "hello1",
      "world2"
    );
    // if (
    //   window &&
    //   Object.prototype.hasOwnProperty.call(window, "flutter_inappwebview")
    // ) {
    //   try {
    //     console.log("call webview handler");
    //     (window as any).flutter_inappwebview.callHandler(
    //       "test",
    //       "hello333",
    //       1234
    //     );
    //   } catch (e) {
    //     console.error(
    //       'window.flutter_inappwebview.callHandler Error: "' + e.message + '"'
    //     );
    //   }
    // }
  }

  public mounted() {
    (window as any).callJS2 = (msg: any) => {
      //和flutter一样的方法
      return this.callJS(msg);
    };
    console.log("web page mounted");
    console.log(localStorage.length);
    console.log(localStorage.getItem("token"));
    console.log(localStorage.getItem("userInfo"));
    console.log("---------------");
    console.log(this.$store.state.token);
    this.form.name = this.$store.state.token;
    console.log(this.$store.state.userInfo.username);
  }

  private sendMsgToFlutter() {
    console.log("开始调用flutter...");
    (window as any).flutter_inappwebview
      .callHandler("test", "hello11", "world22")
      .then(function (result: any) {
        console.log(result);
      });
  }

  private callJS(message: any) {
    this.form.name = message;
    console.log(message);
    return "call js ok";
  }

  private doFlutterScan() {
    console.log("开始调用flutter...");
    const _that = this;
    (window as any).flutter_inappwebview
      .callHandler("runScanner", "param1", "param2")
      .then(function (result: any) {
        console.log(result);
        _that.form.barcode = result;
      });
  }
}
