import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "wvdemo" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { style: {"margin":"16px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_image = _resolveComponent("van-image")!
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_button = _resolveComponent("van-button")!
  const _component_van_uploader = _resolveComponent("van-uploader")!
  const _component_van_form = _resolveComponent("van-form")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_van_image, {
        fit: "fill",
        src: require('../assets/images/header.png')
      }, null, 8, ["src"])
    ]),
    _createVNode(_component_van_cell_group, { title: "用户信息" }, {
      default: _withCtx(() => [
        _createVNode(_component_van_form, { ref: "refForm" }, {
          default: _withCtx(() => [
            _createVNode(_component_van_field, {
              modelValue: _ctx.form.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event)),
              label: "姓名",
              placeholder: "请输入姓名",
              required: "",
              rules: [{ required: true , message: '请输入姓名' }],
              "input-align": "right"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_van_field, {
              modelValue: _ctx.form.idCard,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.idCard) = $event)),
              label: "身份证照片",
              required: "",
              rules: [{ required: true, message: '请输入身份证号' }],
              "input-align": "right"
            }, {
              button: _withCtx(() => [
                _createVNode(_component_van_uploader, {
                  modelValue: _ctx.form.idCardImages,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.idCardImages) = $event)),
                  "after-read": _ctx.afterRead
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_van_button, {
                      size: "small",
                      type: "primary",
                      icon: "photograph"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue", "after-read"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_van_field, {
              modelValue: _ctx.form.barcode,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.barcode) = $event)),
              label: "扫码结果",
              required: "",
              "input-align": "right"
            }, {
              button: _withCtx(() => [
                _createVNode(_component_van_button, {
                  size: "small",
                  type: "primary",
                  icon: "scan",
                  onClick: _ctx.doFlutterScan
                }, null, 8, ["onClick"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_van_button, {
                round: "",
                block: "",
                type: "primary",
                onClick: _ctx.sendMsgToFlutter
              }, {
                default: _withCtx(() => [
                  _createTextVNode("发消息给Flutter")
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          _: 1
        }, 512)
      ]),
      _: 1
    })
  ]))
}